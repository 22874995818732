import React, { Component } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormTextInput, FormButtonOk, FormButtonCancel, InfrastructureWrapper, FormSelect, FormSwitchCheckbox } from '../../components';
import { userService, countryService, shirtSizeService } from '../../services';
import { toSelectOptions } from '../../helpers';
import { configurationActions } from '../../actions';
import { connect } from 'react-redux';

class UserEditProfilePageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {},
            settings: {},
            countries: [],
            shirtSizes: []
        };
    }

    componentDidMount() {
        const { incrementSpinner, decrementSpinner } = this.props;

        this.loadModel();
        this.loadSettings();
        incrementSpinner();
        const promises = [
            this.getResource(countryService.getAll, 'countries', 'Error loading countries'),
            this.getResource(shirtSizeService.getAll, 'shirtSizes', 'Error loading shirt sizes')
        ];

        Promise.all(promises).finally(() => {
            decrementSpinner();
        });
    }

    loadModel = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        userService.getProfile()
            .then(
                response => {
                    const model = response.data;
                    this.setState({ model });
                },
                () => {
                    showError('Error loading model');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    loadSettings = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        userService.getProfileSettings()
            .then(
                response => {
                    const settings = response.data;
                    this.setState({ settings });
                },
                () => {
                    showError('Error loading settings');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    getResource(serviceFunction, stateName, errorMessage) {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        serviceFunction()
            .then(
                response => {
                    const { data } = response;
                    const newState = {};
                    newState[stateName] = data;
                    this.setState(newState);
                },
                () => {
                    showError(errorMessage);
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    generateSelectOptions = () => {
        const { countries, shirtSizes } = this.state;

        const result = {};
        result.countryOptions = toSelectOptions(countries, {
            showDefault: true,
            defaultLabel: '-- Select Country --',
            valueFieldName: 'id',
            labelFieldName: 'description'
        });
        result.shirtOptions = toSelectOptions(shirtSizes, {
            showDefault: true,
            defaultLabel: '-- Select Shirt Size --',
            valueFieldName: 'id',
            labelFieldName: 'name'
        });

        return result;
    }

    render() {
        const { isMasterTech, completeProfile } = this.props;
        const { model, settings } = this.state;
        const { techId, firstName, lastName, emailAddress, streetAddress, city, state, postalCode, country,
            phoneNumber, shirtSize } = model;

        const { learningEmails, otherEmails } = settings;

        const { countryOptions, shirtOptions } = this.generateSelectOptions();

        const saveButtonEnabled = this.isSaveButtonEnabled();

        return (
            <Row>
                <Col md={12}>
                    {isMasterTech && !completeProfile ? (<Alert variant='danger'>Please fill in your personal address (not your dealers); so you can receive your certification, and any relevant apparel. This is time sensitive; Thank you!</Alert>) : null}
                    <fieldset>
                        <legend>Edit Profile</legend>
                        <br />
                        <Row>
                            <Col md={6}>
                                <FormTextInput
                                    fieldName='techId'
                                    fieldValue={techId}
                                    label='Tech ID'
                                    onChange={this.handleValueChanged}
                                    required
                                    readonly />
                                <FormTextInput
                                    fieldName='firstName'
                                    fieldValue={firstName}
                                    label='First Name'
                                    onChange={this.handleValueChanged}
                                    required />
                                <FormTextInput
                                    fieldName='lastName'
                                    fieldValue={lastName}
                                    label='Last Name'
                                    onChange={this.handleValueChanged}
                                    required />
                                <FormTextInput
                                    fieldName='emailAddress'
                                    fieldValue={emailAddress}
                                    label='Email'
                                    onChange={this.handleValueChanged}
                                    readonly={true}
                                    required />
                                <FormTextInput
                                    fieldName='streetAddress'
                                    fieldValue={streetAddress}
                                    label='Street Address'
                                    onChange={this.handleValueChanged}
                                    required />
                                <FormTextInput
                                    fieldName='city'
                                    fieldValue={city}
                                    label='City'
                                    onChange={this.handleValueChanged}
                                    required />
                                <FormTextInput
                                    fieldName='state'
                                    fieldValue={state}
                                    label='State'
                                    onChange={this.handleValueChanged}
                                    required />
                                <FormTextInput
                                    fieldName='postalCode'
                                    fieldValue={postalCode}
                                    label='Postal Code'
                                    onChange={this.handleValueChanged}
                                    required />
                                <FormSelect
                                    fieldName='country'
                                    fieldValue={country}
                                    label='Country'
                                    onChange={this.handleValueChanged}
                                    options={countryOptions}
                                    required />
                            </Col>
                            <Col md={6}>
                                <FormTextInput
                                    fieldName='phoneNumber'
                                    fieldValue={phoneNumber}
                                    label='Phone Number'
                                    onChange={this.handleValueChanged}
                                    required />
                                <FormSelect
                                    fieldName='shirtSize'
                                    fieldValue={shirtSize}
                                    label='Shirt Size'
                                    subLabel='(North American sizes)'
                                    onChange={this.handleValueChanged}
                                    options={shirtOptions}
                                    required />
                                <h4>Email Preferences</h4>
                                <FormSwitchCheckbox
                                    fieldName='learningEmails'
                                    fieldValue={(learningEmails ?? false).toString().toLowerCase() == 'true'}
                                    label='Learning Emails'
                                    onChange={this.handlePreferenceValueChanged}
                                    isBool />
                                <FormSwitchCheckbox
                                    fieldName='otherEmails'
                                    fieldValue={(otherEmails ?? false).toString().toLowerCase() == 'true'}
                                    label='Other Emails'
                                    onChange={this.handlePreferenceValueChanged}
                                    isBool />
                            </Col>
                        </Row>
                        <Row>
                            <br />
                        </Row>
                        <Row>
                            <Col md={6}>
                                {/*<FormButtonOk*/}
                                {/*    title="Save"*/}
                                {/*    onClick={this.handleSaveClick}*/}
                                {/*    disabled={!saveButtonEnabled}>*/}
                                {/*    <i className="fas fa-check"></i> Save*/}
                                {/*</FormButtonOk>*/}
                                <FormButtonCancel title="Cancel" onClick={this.handleCancelClick}>
                                    <i className="fas fa-times"></i> Cancel
                                </FormButtonCancel>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
        );
    }

    isSaveButtonEnabled = () => {
        return false;
        const { model } = this.state;
        const { firstName, lastName, streetAddress, city, state, postalCode, country } = model;

        if (!firstName || !lastName || !streetAddress || !city || !state || !postalCode || !country) {
            return false;
        }

        return true;
    }

    handleValueChanged = (fieldName, value) => {
        const model = { ...this.state.model };

        model[fieldName] = value;

        this.setState({ model });
    }

    handlePreferenceValueChanged = (fieldName, value) => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        const settings = { ...this.state.settings };

        settings[fieldName] = value;
        this.setState({ settings });

        incrementSpinner();
        userService.updateProfileSetting(fieldName, value)
            .then(() => {
            }, (error) => {
                showError('Error saving preferences');
                this.loadSettings();
            })
            .finally(() => {
                decrementSpinner();
            });

    }

    handleCancelClick = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleSaveClick = () => {
        const { model } = this.state;
        const { history, incrementSpinner, decrementSpinner, showError, getUserPermissions } = this.props;

        if (!this.isSaveButtonEnabled()) {
            return;
        }

        incrementSpinner();
        userService.updateProfile(model)
            .then(() => {
                if (window.location.pathname != "/") {
                    history.push("/");
                }
            }, (error) => {
                if (error.response.status == 400) {
                    showError('LMS Error saving profile: ' + error.response.data);
                }
                else {
                    showError('Error saving profile');
                }
            })
            .finally(() => {
                decrementSpinner();
                getUserPermissions();
            });
    }
}

UserEditProfilePageComponent.propTypes = {
    history: PropTypes.object,
    incrementSpinner: PropTypes.func,
    decrementSpinner: PropTypes.func,
    showError: PropTypes.func
};

const mapStateToProps = (state) => ({
    completeProfile: state.configurationReducer.completeProfile,
    isMasterTech: state.configurationReducer.isMasterTech
});

const mapDispatchToProps = (dispatch) => ({
    getUserPermissions: () => dispatch(configurationActions.getPermissions())
});

const UserEditProfilePage = connect(mapStateToProps, mapDispatchToProps)(InfrastructureWrapper(withRouter(UserEditProfilePageComponent)));
export default UserEditProfilePage;